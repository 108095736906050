.about-me {
  background-color: #000000;
  color: #ffffff;
  padding: 20px 40px;
}

.about-me-content {
  max-width: 1280px;
  margin: 0 auto;
}

.about-me-title {
  opacity: 1;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.about-me-description {
  opacity: 1;
}
.about-me-text {
  font-size: 1.5rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .about-me {
    height:900px;
  }
  .about-me-title {
    transform: none !important;
  }
  .about-me-text {
    transform: none !important;
  }
}

@media screen and (max-width: 767px) {
  /* styles */
  .about-me {
    height:1000px;
  }
  .about-me-title {
    transform: none !important;
  }
  .about-me-text {
    font-size: 1.3rem;
    transform: none !important;
  }
  
}

@media screen and (max-width: 480px) {
  .about-me {
    height:1230px;
  }
  .about-me-text {
    font-size: 1.1rem;
  }
}
