.menu {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: #fff;

  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  -webkit-user-select: none;
  user-select: none;
  color: #000;
  /* overflow: hidden; */
  z-index: 5;

  counter-reset: menucounter;

  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 0.1, 1, 0);
}

.menu_container {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}
.menu-bottom-img {
  width: 100vw;
  height: 11.4vw;
  transform-origin: 50% 0;
  background-image: url(./images/menu-bottom.svg);
  background-position: 50% 0;
  background-size: cover;
  position: absolute;
  transform: perspective(1000px);
  bottom: -11.4vw;
}

.hide-menu {
  top: -130%;
}
.nav-divider {
  transition-property: height;
  transition-duration: 1.2s;
  transition-delay: calc(0.03s * var(--number));
  transition-timing-function: ease-out(0, 0.1, 1, 0);
}
.menu-item {
  transition-property: opacity, top;
  transition-duration: 0.7s;
  transition-delay: calc(0.05s * var(--number));
  transition-timing-function: ease-out;
  opacity: 1;
  top: 0px;
}
.hide-menu .menu-item {
  opacity: 0;
  top: -700px;
}

.hide-menu .nav-divider {
  height: 0px;
}

.site-name {
  position: fixed;
  color: #000;
  top: 20px;
  left: 20px;
  font-size: 2rem;
  z-index: 7;
  user-select: none;
  cursor: default;
}
.site-name.menu-displayed {
  color: #fff;
}

.site-name-part2 {
  position: relative;
  top: -10px;
  left: 50px;
}

.menu-btn {
  position: fixed;
  color: #000;
  top: 20px;
  font-size: 2rem;
  cursor: pointer;

  transition: all 0.3s;
  z-index: 6;
  right: -20px;
}

.menu-btn.menu-displayed {
  color: #fff;
}

.menu-btn div {
  display: inline-block;
}

.menu-btn > .menu-btn-effect > span:nth-child(1) span {
  will-change: transform;
  transform-style: preserve-3d;
  transition: 0.5s, opacity 0.3s;
  transition-delay: calc(0.02s * var(--index));
  transform-origin: bottom;
  display: inline-block;

  transform: translate3d(0, -100%, 0) rotateX(-90deg);

  opacity: 0;
}

.menu-btn:hover > .menu-btn-effect > span:nth-child(1) span {
  transform: translate3d(0, 0%, 0) rotateX(0deg);

  opacity: 1;
}

.menu-btn:hover {
  right: 20px;
}

a {
  color: #000000;
  text-decoration: none;
}

.menu-item {
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  width: 20%;
  max-width: 44vh;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  left: 0px;
  /* padding-bottom: 7.6vh; */
  display: flex;
  font-size: 16vh;
}
/* .menu-item::before {
  counter-increment: menucounter;
  content: counters(menucounter,".",decimal-leading);
  position: absolute;
  left: -15px;
  top:0px;
  font-size: 10px;
} */

.nav-txt-wrap {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: center; */
  padding-bottom: 7.6vh;
  display: flex;
  overflow: hidden;
}

.nav-90-div {
  perspective: 40em;
  perspective-origin: 50%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
  transform: rotate(-90deg);
}

.menu-item-text {
  pointer-events: none;
  display: block;
  position: relative;
}

.nav-divider {
  width: 2px;
  height: 100%;
  background-color: #000;
  flex: none;
}

.menu-item > div {
  overflow: hidden;
}

.menu-item > div:nth-child(1) span {
  will-change: transform;
  transform-style: preserve-3d;
  transition: 0.5s, opacity 0.3s;
  transition-delay: calc(0.02s * var(--index));
  transform-origin: bottom;
  display: inline-block;
}

.menu-item > div:nth-child(2) span {
  will-change: transform;
  transform-style: preserve-3d;
  transition: 0.5s, opacity 0.3s;
  transition-delay: calc(0.02s * var(--index));
  transform-origin: top;
  display: inline-block;
  transform: translate3d(0, 100%, 0) rotateX(-115deg);
  opacity: 0;
}

.menu-item:hover > div:nth-child(1) span {
  transform: translate3d(0, -100%, 0) rotateX(-115deg);
  opacity: 0;
}

.menu-item:hover > div:nth-child(2) span {
  transform: translate3d(0, 0%, 0) rotateX(0deg);
  opacity: 1;
}

.my-details-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 8%;
  /* justify-content: center; */
}

.details-container .email {
  margin-top: 10%;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

/* .social-icons a:hover {
  transform: translate(-10px, -10px) rotateX(10deg) rotateY(10deg);
} */

.tilt-box-wrap:hover {
  transition: all 0.3s linear;
  transform: scale(1.2);
}
.tilt-box-wrap {
  width: 26px;
  height: 30px;
  position: relative;
  transition: transform 0.3s ease-out;
  perspective: 1000px;
  cursor: pointer;
}
.tilt-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 8px;
  background-color: #e5e5e5;

  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  transform: rotateX(0deg) rotateY(0deg);
  perspective: 1000px;
  transform-style: preserve-3d;
}
.tilt-box strong {
  transform: translateZ(10px);
}

.t_over {
  width: 33.333%;
  height: 33.333%;
  position: absolute;
  z-index: 1;
}

.t_over:nth-child(1) {
  left: 0;
  top: 0;
}
.t_over:nth-child(2) {
  left: 33.333%;
  top: 0;
}
.t_over:nth-child(3) {
  left: 66.666%;
  top: 0;
}
.t_over:nth-child(4) {
  left: 0;
  top: 33.333%;
}
.t_over:nth-child(5) {
  left: 33.333%;
  top: 33.333%;
}
.t_over:nth-child(6) {
  left: 66.666%;
  top: 33.333%;
}
.t_over:nth-child(7) {
  left: 0;
  top: 66.666%;
}
.t_over:nth-child(8) {
  left: 33.333%;
  top: 66.666%;
}
.t_over:nth-child(9) {
  left: 66.666%;
  top: 66.666%;
}

.t_over:nth-child(1):hover ~ .tilt-box {
  transform: rotateX(-10deg) rotateY(10deg);
}
.t_over:nth-child(2):hover ~ .tilt-box {
  transform: rotateX(-10deg) rotateY(0deg);
}
.t_over:nth-child(3):hover ~ .tilt-box {
  transform: rotateX(-10deg) rotateY(-10deg);
}
.t_over:nth-child(4):hover ~ .tilt-box {
  transform: rotateX(0deg) rotateY(10deg);
}
.t_over:nth-child(5):hover ~ .tilt-box {
  transform: rotateX(0deg) rotateY(0deg);
}
.t_over:nth-child(6):hover ~ .tilt-box {
  transform: rotateX(0deg) rotateY(-10deg);
}
.t_over:nth-child(7):hover ~ .tilt-box {
  transform: rotateX(10deg) rotateY(10deg);
}
.t_over:nth-child(8):hover ~ .tilt-box {
  transform: rotateX(10deg) rotateY(0deg);
}
.t_over:nth-child(9):hover ~ .tilt-box {
  transform: rotateX(10deg) rotateY(-10deg);
}

@media screen and (max-width: 991px) {
  .nav-90-div {
    transform: rotate(0deg);
    justify-content: center;
    align-items: center;
  }
  .menu_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu-item {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    left: 0px;
    font-size: 9vh;
  }

  .menu-item:nth-child(1) {
    margin-top: 6vh;
  }

  .nav-divider {
    width: 100%;
    height: 2px;
  }

  .nav-txt-wrap {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 1.6vh;
    display: flex;
    overflow: hidden;
    padding-top: 1.6vh;
  }

  .menu .my-details-section {
    width: auto;
    margin: 0;
  }

  .menu .details-container {
    text-align: center;
  }
  .menu .details-container .mobile,
  .menu .details-container .email {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  /* styles */
  .menu-item:nth-child(1) {
    margin-top: 12vh;
  }
  .menu-btn:hover {
    right: 30px;
  }
}

@media screen and (max-width: 480px) {
  /* styles */
  .menu-item:nth-child(1) {
    margin-top: 12vh;
  }
}
