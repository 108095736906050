@font-face {
  font-family: "Old Standard TT Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Old Standard TT Regular"),
    url("fonts/OldStandard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Old Standard TT Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Old Standard TT Italic"),
    url("fonts/OldStandard-Italic.woff") format("woff");
}

@font-face {
  font-family: "Old Standard TT Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Old Standard TT Bold"),
    url("fonts/OldStandard-Bold.woff") format("woff");
}
