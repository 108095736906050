/* Styles for the container that holds the scattered shapes */
.math-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 45%;
}

.shape {
  position: absolute;
}

.shape1 {
  top: calc(10% + 20vh * var(--random-1));
  left: calc(0% + 30vw * var(--random-2));
}

.shape2 {
  top: calc(20% + 10vh * var(--random-3));
  left: calc(10% + 20vw * var(--random-4));
}

.shape3 {
  top: calc(5% + 20vh * var(--random-5));
  left: calc(15% + 20vw * var(--random-6));
}

.shape4 {
  top: calc(1% + 80vh * var(--random-7));
  left: calc(10% + 40vw * var(--random-8));
}

.shape5 {
  top: calc(30% + 10vh * var(--random-9));
  left: calc(50% + 20vw * var(--random-10));
}
/* 3D Pyramid */
.pyramid {
    width: 100px;
    height: 100px;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid #debd20;
  transform: rotateX(-45deg) rotateY(45deg);
}

/* Dodecahedron */
.dodecahedron {
  width: 100px;
  height: 100px;
  background-color: #debd20;
  position: relative;
}

.dodecahedron:before,
.dodecahedron:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.dodecahedron:before {
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-right: 50px solid #debd20;
  top: -30px;
  left: 50px;
}

.dodecahedron:after {
  border-bottom: 60px solid transparent;
  border-top: 60px solid transparent;
  border-left: 50px solid #debd20;
  top: -30px;
  left: -50px;
}

/* Icosahedron */
.icosahedron {
  width: 100px;
  height: 100px;
  background-color: #debd20;
  position: relative;
  transform: rotateX(45deg) rotateY(45deg) rotateZ(45deg);
}

.icosahedron:before,
.icosahedron:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.icosahedron:before {
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 40px solid #debd20;
  top: -10px;
  left: 0;
}

.icosahedron:after {
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  border-top: 40px solid #debd20;
  bottom: -10px;
  left: 0;
}

/* Pentagonal Prism */
.pentagonal-prism {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 80px solid #debd20;
  transform: rotateX(45deg) rotateY(45deg);
}

/* Octahedron */
.octahedron {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 50px solid #debd20;
  position: relative;
  transform: rotateX(45deg) rotateY(45deg);
}

.octahedron:before,
.octahedron:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.octahedron:before {
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 50px solid #debd20;
  top: -50px;
  left: 0;
}

.octahedron:after {
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  border-bottom: 50px solid #debd20;
  bottom: -50px;
  left: 0;
}
