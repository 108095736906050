.animated-shapes-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
    overflow: hidden;
  }
  
  .animated-shapes-container .shape {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    opacity: 0.7;
  }
  
  .animated-shapes-container .shape1 {
    background-color: #debd20;
    animation: moveShapes1 10s infinite;
  }
  
  .animated-shapes-container .shape2 {
    background-color: #b33f4c;
    animation: moveShapes2 8s infinite;
  }
  
  .animated-shapes-container .shape3 {
    background-color: #debd20;
    animation: moveShapes3 12s infinite;
  }
  
  .animated-shapes-container .parallax {
    transform: translateZ(0);
  }
  
  @keyframes moveShapes1 {
    0% {
      left: 50%;
      bottom: 0%;
      transform: translate(-50%, 0);
    }
    100% {
      left: 50%;
      top: 0%;
      transform: translate(-50%, -200px);
    }
  }
  
  @keyframes moveShapes2 {
    0% {
      left: 50%;
      bottom: 0%;
      transform: translate(-50%, 0);
    }
    100% {
      left: 50%;
      top: 0%;
      transform: translate(-50%, -300px);
    }
  }
  
  @keyframes moveShapes3 {
    0% {
      left: 50%;
      bottom: 0%;
      transform: translate(-50%, 0);
    }
    100% {
      left: 50%;
      top: 0%;
      transform: translate(-50%, -250px);
    }
  }
  