.contact {
  background-color: #debd20;
  color: #000000;
  padding: 100px 0;

  padding-bottom: 0;
  margin-bottom: 0;
}

.contact-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.contact-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.contact-form input,
.contact-form textarea {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #888888;
  border-radius: 5px;
}

.contact-form textarea {
  resize: vertical;
}

.contact-btn {
  display: inline-block;
  background-color: #b33f4c;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-btn:hover {
  background-color: #941428;
}

.scrolling-contact-text {
  display: inline-block;
  font-family: Tuskergrotesk, sans-serif;
  font-size: 6.3rem;
  font-weight: 500;
  white-space: nowrap;

  will-change: transform;
  transform: translate(950px, 0px);
  /* transform: translate3d(-269.79px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d; */
  color: #b33f4c;
}
.scrolling-contact-text .center-text {
  font-family: "Old Standard TT Italic";
  display: inline-block;
  margin: 0 20px;
}
.scrolling-contact-text .side-text {
  font-family: "Old Standard TT Regular";
  display: inline-block;
}

.contact .my-details-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 0%;
  /* justify-content: center; */
  /* padding-right: 5%; */
  /* margin-top: 185px; */
}

.contact .details-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}

.contact .details-container .email {
  margin-top: 2%;
}

.contact .social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  margin-top: 0px;
}

.me-section {
  margin-top: 150px;
  display: flex;
  /* text-align: center; */
  align-items: center;
  flex-direction: row;
  /* justify-content: space-between; */
}

.me-section .me-section-right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.locate-me {
  margin-top: 30px;
}

.its-me {
  background-color: #debd20;
}

.its-me img {
  top: 3px;
  position: relative;
}

@media screen and (max-width: 991px) {
  .scrolling-contact-text {
    transform: translate(60px, 0px);
  }

  .contact .social-icons {
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  /* styles */
}

@media screen and (max-width: 480px) {
  /* styles */
  .contact .details-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .contact .details-container .details-right {
    margin-top: 20px;
  }
  .scrolling-contact-text {
    display: flex;
    flex-direction: column;
    font-size: 4.5rem;
    align-items: center;
    transform: translate(0px, 0px) !important;
  }
  .its-me img {
    height: 250px;
  }
}
