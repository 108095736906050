.projects {
    background-color: #debd20;
    color: #000000;
    padding: 100px 0;
  }
  
  .projects-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .projects-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .project-box {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.34);
    text-align: center;
  }
  
  .project-icon {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .project-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .project-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .project-category {
    font-size: 1rem;
    color: #333333;
  }
  