.header {
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    padding: 80px 0;
    position: relative;
    overflow: hidden;
    
    height: calc(50vh);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .header-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .header-title {
    font-size: 3.5rem;
    margin: 0;
  }
  
  .header-subtitle {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  /* Add styles for the floating navigation button or hamburger menu */
  