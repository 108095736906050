* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;

}
html {
  scroll-behavior: smooth !important;
}
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    color: #ffffff;
    background-color: #000000;
    scroll-behavior: smooth !important;
  }
  .App
  {
    scroll-behavior: smooth;
    overflow-y: hidden;
  /* overflow-x: hidden; */
  overflow: hidden;
    width: 100%;
    position: relative;
  }
  
  .section {
    scroll-behavior: smooth !important;
    padding: 100px 20px;
    overflow: hidden;
  }
  
  .black {
    background-color: #000000;
  }
  
  .yellow {
    background-color: #debd20;
  }
  
  .red {
    background-color: #b33f4c;
  }

  
  .last-section
  {
    margin-bottom: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  

  .all-sections-content ::selection {
    color: #000;
    background: #debd20;
  }

  .section.yellow ::selection {
    color: #fff;
    background: #b33f4c;
  }

  .section.red ::selection {
    color: #000;
    background: #debd20;
  }

  #about-me
  {
    padding: 0px 20px;
  }
  
  /* Add styles for the header, about-me, projects, skills, and contact components as needed */
  /* body {
    font-family: Montserrat, sans-serif;
    font-weight: 900;
  } */
  body, body * {
    /* cursor: none; */
  }

  .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: #ffffff;
    transform-origin: 0%;
  }
  

  .hover_effect
  {
    pointer-events: none;
  }
  .hover_effect_shapes {
    position: absolute;
    height: 100vh;
    width: 100vw;
    /* background: #2128bd; */
    opacity: 0;
    overflow: hidden;
    
  }
  .hover_effect_shape {
    will-change: transform;
    position: absolute;
    border-radius: 50%;
    z-index: 99999;
  }
  /* .hover_effect_shape.hover_effect_shape-1 {
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,100) 0%, rgba(254,254,254,0) 75%);
    width: 650px;
    height: 650px;
    margin: -325px 0 0 -325px;
    
  }
  .hover_effect_shape.hover_effect_shape-2 {
    background: rgb(222,189,32);
    background: radial-gradient(circle, rgba(222,189,32,100) 0%, rgba(222,189,32,0) 75%);
    width: 440px;
    height: 440px;
    margin: -220px 0 0 -220px;
  }
  .hover_effect_shape.hover_effect_shape-3 {
    background: rgb(179,63,76);
    background: radial-gradient(circle, rgba(179,63,76,100) 0%, rgba(179,63,76,0) 75%);
    width: 270px;
    height: 270px;
    margin: -135px 0 0 -135px;
  } */
  .hover_effect_shape.hover_effect_shape-1 {
    background: rgb(255,255,255);
    width: 10px;
    height: 10px;
    margin: -5px 0 0 -5px;
    opacity: 1;
    
  }
  .hover_effect_shape.hover_effect_shape-2 {
    background: rgb(222,189,32);
    width: 16px;
    height: 16px;
    margin: -8px 0 0 -8px;
    opacity: .74;
  }
  .hover_effect_shape.hover_effect_shape-3 {
    background: rgb(179,63,76);
    width: 22px;
    height: 22px;
    margin: -11px 0 0 -11px;
    opacity: .34;
  }
  
  .hover_effect_shape.hover_effect_shape-1, .hover_effect_shape.hover_effect_shape-2, .hover_effect_shape.hover_effect_shape-3 {
    /* opacity: 1; */
  }

  .hover_effect_cursor {
    position: fixed;
    background: #2128bd;
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;
    border-radius: 50%;
    will-change: transform;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    display: none;
  }
  
  .all-sections-content
  {
    mix-blend-mode: screen;
    
  }

  

@media screen and (max-width:991px){
    .hover_effect
    {
      display: none;
    }
}   

@media screen and (max-width:767px){
    /* styles */
}

@media screen and (max-width:480px){
    /* styles */
}