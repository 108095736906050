.skills {
    background-color: #b33f4c;
    color: #ffffff;
    padding: 100px 0;
    overflow: hidden;
  }
  
  .skills-content {
    max-width: 800px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
  }
  
  .skills-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    color: #000;
  }
  
  .skill-box {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.34);
    text-align: center;
  }
  
  .skill-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .skill-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  